.wrapper {
  position: relative;
  height: calc(100vh - 150px);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
}

.content {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding: 5px 10px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.content::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.content:hover {
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.shadow {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 10px;
  background: red;
}

.shadow--top {
  top: 0;
  height: 20px;
  background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.3), transparent);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), transparent);
  opacity: 0;
}

.shadow--bottom {
  bottom: 0;
  height: 20px;
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.3), transparent);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), transparent);
  opacity: 0;
}
