.button {
  border-color: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 0.5);
  width: 29px;
  height: 29px;
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px 4px 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.button:hover {
  background-color: #eeeeee;
  cursor: pointer;
}
