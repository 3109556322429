.time{
    padding: 5px;
    font-size: 9px;
    border-radius: 8px;
    border: 1px solid white;
    cursor: pointer;
}
.time:hover{
    border: 1px solid rgb(226, 226, 226);
    background-color: rgb(234,234,234);
}
.selectedTime{
    background-color: rgba(194, 0, 0, 0.82);
    color: white;
}
.selectedTime:hover{
    background-color: rgba(194, 0, 0, 0.82);
    color: white;
}